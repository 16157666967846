/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-08-16 19:19:41
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-21 14:15:10
 * @FilePath: /aurora-carton-web/src/utils/tool/getQueryHeight.js
 * @Description: 动态获取dom高度
 */
import {nextTick, onMounted, onUnmounted, ref} from 'vue';

export function getDomHeight(dom = '.queryBoxOne') {
    const domHeight = ref('');
    onMounted(async () => {
        await nextTick();
        getDomHeightFn()
        window.addEventListener('resize', getDomHeightFn)
    })
    onUnmounted(() => {
        window.removeEventListener('resize', getDomHeightFn)
    })

    function getDomHeightFn() {
        if (document.querySelector(dom)) {
            domHeight.value = document.querySelector(dom).clientHeight + 'px';
            setTimeout(() => {
                domHeight.value = document.querySelector(dom).clientHeight + 'px';
            }, 400);
        }
    }

    return {
        domHeight
    }
}