<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-07-27 16:51:18
 * @FilePath: /aurora-carton-web/src/components/fieldsCheck/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-dialog
        title="展示项设置"
        width="920"
        class="checkModal"
        v-bind="$attrs"
        @close="closeDia"
        @open='openDia'
      >
      <div class="fieldsContent">
        <el-checkbox-group v-model="checkList">
          <el-checkbox :label="item" :checked="item.isShow" v-for='(item,index) in list'
                       :disabled="disable(item.fieldKey)" :key='index'>{{ item.title }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <template #footer>
          <el-button @click="closeDia">取消</el-button>
          <el-button class="main" type="primary" @click="handleOk">
            保存
          </el-button>
      </template>
    </el-dialog>
</template>
<script setup>
import {cloneDeep} from 'lodash';
import {defineEmits, defineProps, ref, toRefs} from "vue";

const props = defineProps({
    list:{
      type:Array,
      default:[]
    },
  }
)
const { list } = toRefs(props)
const oldList = ref([])
const emit = defineEmits(['close'])
const checkList = ref([])
//关闭弹窗
const closeDia = ()=>{
  emit('close')
  checkList.value = cloneDeep(oldList.value)
}

let isFirst = true

const openDia = ()=>{
  if(isFirst){
    oldList.value = cloneDeep(list.value)
    isFirst = false
  }
}

const disable = (key) => {
  return key === 'productName' || key === 'img';
}


//点击确认
const handleOk = ()=>{
  oldList.value = []
  for (let i = 0; i < list.value.length; i++) {
    let index = checkList.value.findIndex(item=>item.title == list.value[i].title)
    if(index == -1){
      list.value[i].isShow = false
    }else{
      list.value[i].isShow = true
      oldList.value.push(list.value[i])
    }
  }
  emit('close')
}

</script>

<style lang="scss" scoped>
   .checkModal{
    .fieldsContent{
      padding: 0 32px;
      .el-checkbox-group{
        .el-checkbox{
          margin-right: 60px;
          margin-bottom: 20px;
        }
      }
    }
  }
</style>